<template lang="pug">
div()
  v-list(subheader two-line)
    div(
      v-for='(item, i) in itemsSorted'
      :key='i'
    )
      v-list-tile(
        :class="tileClass(item)"
        v-if="item.schedType === 'line'"
        avatar
      ).list-item
        v-list-tile-avatar(v-if="item.dashboard.image.url" tile @click="select(item)")
          img(:src="item.dashboard.image.url")
        v-list-tile-content(@click="select(item)")
          v-list-tile-title
            slot(name="line1" :item="item")
          v-list-tile-sub-title
            slot(name="line2" :item="item")

      v-list-tile.list-item.black.white--text(
        v-if="item.schedType === 'date'"
      )
        v-list-tile-content
          v-list-tile-title.font-weight-black.title {{ item._tempDate }}  - production date

</template>
<script>
import moment from 'moment'
import scheduleSort from '../../../helpers/schedule-sort-schedule'

export default {
  props: {
    items: { type: Array }
  },
  computed: {
    itemsSorted () { return scheduleSort(this.items) }
  },
  methods: {
    select (item) { this.$emit('select', item) },
    tileClass (item) {
      if (item.dashboard.isPressCheck) { return 'highlight-presscheck' }
      const ddDate = item.dates.dropDeadOverride ? item.dates.dropDeadOverride : item.dates.dropDead
      const isDateWarning = ddDate ? ddDate < item.dates.schedule : false
      if (isDateWarning) { return 'highlight-warning' }
    }
  }
}
</script>

<style lang="stylus" scoped>
.highlight-warning
  background-color #F8BBD0
.highlight-presscheck
  background-color #69F0AE
</style>
