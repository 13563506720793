import moment from 'moment'
import { ProductionInstance } from '@/models'

export default (inputList) => {
  const items = inputList.map(x => x)

  // first sort items by date and then priority
  let itemsSorted = items.sort((a, b) => {
    // sort by assigned production date
    // const A = a.dateProduction
    // const B = b.dateProduction
    // if (A > B) return 1
    // if (A < B) return -1
    // // within each day, sort by shift
    // const C = a.shift
    // const D = b.shift
    // if (C > D) return 1
    // if (C < D) return -1
    // // within each day, sort by task
    // if (a.task.isTask) return 1
    // // within each shift, sort by priority
    // const E = a.dashboard.priority.production
    // const F = b.dashboard.priority.production
    // if (E > F) return 1
    // if (E < F) return -1

    const A = a._sortSchedule
    const B = b._sortSchedule
    if (A > B) return 1
    if (A < B) return -1
    return 0
  })

  itemsSorted = itemsSorted.map(item => {
    item.schedType = 'line'
    return item
  })

  /// /////
  // prepare date subsummary lines
  /// /////

  // account for an array of a single item
  if (itemsSorted.length <= 1) { return itemsSorted }

  // working backwards from end of list, the last item will be considered the 'previous item' in the loop
  let previousItem = itemsSorted[itemsSorted.length - 1]

  // more than one item, start at second to last
  for (let i = itemsSorted.length - 2; i >= 0; --i) {
    // find out the adjacent dates
    const previousDate = previousItem.dates.schedule
    const currentDate = itemsSorted[i].dates.schedule

    // if the date has changed from a previous item splice in a date line
    if (currentDate !== previousDate) {
      itemsSorted.splice(i + 1, 0, new ProductionInstance({
        schedType: 'date',
        _tempDate: moment(previousDate).format('LL'),
        dates: { schedule: previousDate }
      }))
    }

    // if the last item is reached check to see if those items are unscheduled otherwise splic in a date line
    if (i === 0 && currentDate === '') {
      itemsSorted.splice(0, 0, new ProductionInstance({
        schedType: 'date',
        _tempDate: 'unscheduled'
      }))
    } else if (i === 0 && currentDate !== '') {
      itemsSorted.splice(0, 0, new ProductionInstance({
        schedType: 'date',
        _tempDate: moment(currentDate).format('LL'),
        dates: { schedule: currentDate }
      }))
    }

    // set previous item to current item
    previousItem = itemsSorted[i]
  }

  return itemsSorted
}
